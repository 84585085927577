export const CommonService = { 
   httpPostService,
   httpPutService,
   httpGetService,
   httpDeleteService,
   httpFileUpload,
   getBaseUrl,
   getCentrifugeUrl,
   publishToCentrifuge,
   httpDeleteBodyService,
   httpPutPaymentService,
   downloadPdf
};

const baseUrl = process.env.baseUrl;
const centrigufeWsUrl = process.env.centrigufeWsUrl;
const centrigufeApiUrl = process.env.centrigufeApiUrl
const paymentUrl = process.env.pg_url

function getBaseUrl(){
    return baseUrl
}
function getCentrifugeUrl(type){
    if (type == 'ws') {
        return centrigufeWsUrl
    }else if(type == 'api'){
        return centrigufeApiUrl
    }else{
        return baseUrl
    }
    
}
function httpPostService(url,data) {
  
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json' 
        },
        body: JSON.stringify(data)
    };

    if (window.navigator.cookieEnabled) {
        let authToken;
        if (localStorage.getItem('user')) {
            authToken = JSON.parse(localStorage.getItem('user')).access_token
        }
        if (authToken) {
            requestOptions['headers']['Authorization'] = `Bearer ${authToken}`
        }
    }

    return fetch(`${baseUrl}/${url}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

async function  getCentrifugeApiToken()  {
    // setLoading(true);
    return new Promise(async (resolve, reject) => {
        httpGetService(`admin-api/centrifuge_auth`)
        .then(data => {
            if (data.token) {
                resolve(data.token)
            }else{
                resolve('')
            }
        }, error => {
            resolve('')
        })
    })
}
async function  publishToCentrifuge(data,token) {
    console.log('Centrifuge Token',token)
    if(token == undefined || token == null || token == ''){
        token = await getCentrifugeApiToken ()
    }
    console.log('Centrifuge Token',token)
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json, text/javascript, */*; q=0.01',
            'Authorization': `token ${token}`,
            "Content-Type": 'application / json',
            // "Access-Control-Allow-Origin": '*',
            // "Access-Control-Allow-Credentials": true,
            // "Access-Control-Allow-Methods": 'GET, POST, OPTIONS',
            // "Access-Control-Allow-Headers": "DNT,X-CustomHeader,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content"
        },
        body: JSON.stringify(data)
    };

    return fetch(centrigufeApiUrl, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function httpPutService(url, data) {
   
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json' 
        },
        body: JSON.stringify(data)
    };

    if (window.navigator.cookieEnabled) {
        let authToken;
        if (localStorage.getItem('user')) {
            authToken = JSON.parse(localStorage.getItem('user')).access_token
        }
        if (authToken) {
            requestOptions['headers']['Authorization'] = `Bearer ${authToken}`
        }
    }
    return fetch(`${baseUrl}/${url}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function httpPutPaymentService(url, data) {
    
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json' 
        },
        body: JSON.stringify(data)
    };
    if (window.navigator.cookieEnabled) {
        let authToken;
        if (localStorage.getItem('user')) {
            authToken = JSON.parse(localStorage.getItem('user')).access_token
        }
        if (authToken) {
            requestOptions['headers']['Authorization'] = `Bearer ${authToken}`
        }
    }

    return fetch(`${paymentUrl}/${url}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function httpGetService(url) {
   
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    if (window.navigator.cookieEnabled) {
        let authToken;
        if (localStorage.getItem('user')) {
            authToken = JSON.parse(localStorage.getItem('user')).access_token
        }
        if (authToken) {
            requestOptions['headers']['Authorization'] = `Bearer ${authToken}`
        }
    }

    return fetch(`${baseUrl}/${url}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        });
}

function httpDeleteService(url) {
    
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
    };

    if (window.navigator.cookieEnabled) {
        let authToken;
        if (localStorage.getItem('user')) {
            authToken = JSON.parse(localStorage.getItem('user')).access_token
        }
        if (authToken) {
            requestOptions['headers']['Authorization'] = `Bearer ${authToken}`
        }
    }

    return fetch(`${baseUrl}/${url}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        });
}

function downloadPdf(url) {
    
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json', 
            'Accept' : 'application/pdf'
         },
    };

    return fetch(url, requestOptions)
    .then( res => res.blob() )
    .then( blob => 
        {return blob});
}

function httpDeleteBodyService(url,data) {

   
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    if (window.navigator.cookieEnabled) {
        let authToken;
        if (localStorage.getItem('user')) {
            authToken = JSON.parse(localStorage.getItem('user')).access_token
        }
        if (authToken) {
            requestOptions['headers']['Authorization'] = `Bearer ${authToken}`
        }
    }

    return fetch(`${baseUrl}/${url}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        });
}


function httpFileUpload(url,data) {
   
    const requestOptions = {
        method: 'POST',
        file: data
    };

    if (window.navigator.cookieEnabled) {
        let authToken;
        if (localStorage.getItem('user')) {
            authToken = JSON.parse(localStorage.getItem('user')).access_token
        }
        if (authToken) {
            requestOptions['headers']['Authorization'] = `Bearer ${authToken}`
        }
    }

    return fetch(`${baseUrl}/${url}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        });
}


function handleResponse(response) {
    return response.text().then(text => { 
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
        
    });
}