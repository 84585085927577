import React, { useState, useContext, useEffect } from "react";
import "./class.css";
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Header from '../../common/header/header'
import { CommonService } from '../../service/common.service';
import LoadingOverlay from 'react-loading-overlay';
import Container from "react-bootstrap/Container";
import * as moment  from "moment";
import defaultTeacher from '../../assets/defaultteacher.png'
import { Link } from "react-router-dom";
import { history } from '../../_helpers/history'
import * as FileSaver from 'file-saver'; 
import { useAlert } from "react-alert";
// import Breadcrumb from 'react-bootstrap/Breadcrumb'


const classMain = ({ match }) => {
	const [loading, setLoading] = useState(false);
	const alert = useAlert();
    const [data, setData] = useState([]);
    const [previousClassData, setPreviousClassData] = useState([]);
    const [asCoTeacherCLassData, setAsCoTeacherCLassData] = useState([]);
	const courseId = match.params?.id
	const [courseName, setCourseName] = useState(''); 
    // const [startClassBtnName, setBtnName] = useState('Start'); 
    const [role, setRole] = useState('Start'); 
    
	// const courseId = match.params?.id
	useEffect(() => {
		var userData = {}
		if (localStorage.getItem('user') != null && localStorage.getItem('user') != undefined) {
			userData = JSON.parse(localStorage.getItem('user'))
			setRole(userData.role)
			// if (userData.role == 'STUDENT') {
			// 	setBtnName('Join')
			// }
		}

		const getClasses = async () => {
			setLoading(true);
			CommonService.httpGetService(`admin-api/class/get-classes/${userData.role}/${userData.id}/${courseId}`).then(res => {
				// for (let i = 0; i < 20; i++) {
				// 		res.push(res[0])
				// }
				setData(res);
				console.log('Upcoming CLasses : ',res)
                getCourses(res[0].course_id);
				setLoading(false);
			}, error => {
				setData([])
				setLoading(false);
			})

		}

		getClasses();

		if (userData.role != 'STUDENT') {
			const getPreviousClasses = async () => {
				setLoading(true);
				CommonService.httpGetService(`admin-api/class/get-previous-classes/${userData.id}/${courseId}`).then(res => {
					console.log('Previous CLasses : ',res)
					setPreviousClassData(res.data);
					setLoading(false);
				}, error => {
					setPreviousClassData([])
					setLoading(false);
				})
		
			}
			getPreviousClasses();

			const getAssistedClasses = async () => {
				setLoading(true);
				CommonService.httpGetService(`admin-api/class/get-assitant-teacher-classes/${userData.id}/${courseId}`).then(res => {
					console.log('Assisted CLasses : ',res)
					setAsCoTeacherCLassData(res.data);
					setLoading(false);
				}, error => {
					setPreviousClassData([])
					setLoading(false);
				})
		
			}
			getAssistedClasses();
		}
	}, []);

	
	
	const getCourses = async (id) => {
        try {
            setLoading(true);
            CommonService.httpGetService(`admin-api/courses?id=${id}`)
            .then(data => {
                setCourseName(data[0].name)
                setLoading(false);
            }, error => {
                alert.error(error)
                setLoading(false);
            })
        }
        catch {
            alert.error("Please fill valid details")
        }
    }
    
    function getRandomColor() {
         let myColors = ['kt-circle-1', 'kt-circle-2', 'kt-circle-3', 'kt-circle-4', 'kt-circle-5',
         'kt-circle-6', 'kt-circle-7', 'kt-circle-8', 'kt-circle-9' ];
         let color = myColors[Math.floor(Math.random() * myColors.length)];
        //  setbgColor(color);
         return color
        
	  }
	  
	const startClass = (classData) => {
		setLoading(true)
		console.log(classData);
		let reqObj = {
			"class_id": classData.id,
			"class_url" : `${CommonService.getBaseUrl()}/course/${classData.course_id}/${classData.id}/${classData.teacher_id}/start?channel_name=${classData.channel_name}`
		}
		 
		CommonService.httpPostService(`admin-api/class/start`, reqObj)
			.then(resp => {
				setLoading(false)
				moveToClass(classData)
			}, error => {
				setLoading(false)
				alert.error(error)
			})
	}
	const moveToClass = (classData) => {
		if (moment(classData.start_date).isSameOrBefore(new Date())) {
			history.push(`/course/${classData.course_id}/${classData.id}/${classData.teacher_id}/start?channel_name=${classData.channel_name}`);
		}else{
			alert.error('Join Class on schedule date.',{
				timeout: 5000, 
			})
		}
		// 
	}

	const moveToSignContract = (classData) =>{
		history.push(`/contract/${classData.id}`)
	}

	const downloadCertificate = (fileURL, fileName) => {
        CommonService.downloadPdf(fileURL)
        .then(fileBlob => {
             
            // let fileBlob = res.blob();
			let blob = new Blob([fileBlob], { 
			   type: 'application/pdf'
			});
			FileSaver.saveAs(blob, fileName);
        }, error => {
            
        })
        }


	return (
		<LoadingOverlay
			active={loading}
			spinner
			text='Loading...'
			className="loaderStyle"
		>
			<Container className="containerBox" fluid>
				<Header></Header>

				{
					data && data.length > 0 ? <>	
					<div className="dark-blue-bg"></div>	
				<Container fluid className="w-90per mt-100minus">
					<h2 className="pageTitle mt-50 text-center">UPCOMING CLASSES</h2>	
					<div className="row ">
						{
							data && data.map((datal, ranIndex) => (
								
                                <div key={ranIndex} className="col-lg-4 col-md-4 col-sm-6 col-xs-12 animate__animated animate__fadeInUp" >
								{/* <Col lg={4} md={6} sm={12} > */}
									<Card className="course-card shadow-box">
										<Card.Body style={{borderRadius: "15px"}} >
											<Card.Title style={{fontWeight: 600}}  >{datal.name ? datal.name : datal.course_id }</Card.Title>
											<div>
												<Card.Subtitle className="mb-2 mt-20 text-muted " style={{fontSize: "14px"}}><p className="fontwt mb-1">Schedule</p> { moment(datal.start_date).format('MMM DD') } - { moment(datal.end_date).format('MMM DD') }
												</Card.Subtitle>
												<Card.Subtitle className="mb-2 mt-20 text-muted " style={{fontSize: "14px"}}><p className="fontwt mb-1">Slot</p> { moment(datal.start_time , 'HH:mm:ss').format('HH:mm') } - { moment(datal.end_time , 'HH:mm:ss').format('HH:mm') } </Card.Subtitle>
											</div>  
											
											{/* @TODO : FIX Time slot binding */}
                                                 <div className="row my-btns  mt-25">
												 <div className=" col-sm-6"  style={{width: role =='STUDENT' && datal.course_certificate_generated == false ? "auto" : "auto"}}  onClick={()=> {
													 if (role != 'STUDENT') {
														 startClass(datal)
													 }else if(datal.signed_contract){
														moveToClass(datal)
													 }else{
														moveToSignContract(datal)
													 }
												 }} >
                                                     <b className="btn active">{role != 'STUDENT' ? 'Start Class' : (
														 <>
														 {
															datal.signed_contract == true ? 'Join Class' : 'Join Class' 
														 }
														 </>
													 )}</b>
                                                 </div>
												 {
													 role != 'STUDENT' || (role == 'STUDENT' && datal.course_certificate_generated == true)  ? (
                                                            <Dropdown className=" col-sm-6" >
                                                            <Dropdown.Toggle variant="" id="dropdown-basic" className="btn active">
																<span >More  <i className="fas fa-ellipsis-v "></i></span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="read-status">
                                                            {
																role == 'STUDENT' && datal.course_certificate_generated == true ? <Dropdown.Item onClick={() => downloadCertificate(datal.course_certificate_url,`${datal.name}.pdf`) }><span>Download Certificate</span></Dropdown.Item> : ''
															}
                                                            {
																role != 'STUDENT' ? 
																<Dropdown.Item href={`/update-class/${datal.id}`}><span>Edit</span></Dropdown.Item> : ''
															}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
													 ) : ''
													   }
												 </div>
										</Card.Body>
									</Card>
								{/* </Col>                                 */}
                                </div>
							))
						}


					</div>
				</Container>
					</> : ''
				}
				{
					role != 'STUDENT' && previousClassData && previousClassData.length > 0 ? <>
				
				<Container fluid className="w-90per">				
				<h2 className="pageTitle mt-50 text-left text-black">PREVIOUS CLASSES</h2>	
					<div className="row ">
						{
							previousClassData && previousClassData.map((datal, ranIndex) => (
									<div key={ranIndex} className="col-lg-4 col-sm-6 col-xs-12">
										<Card className="classesCard shadow-box">
											<Card.Body style={{borderRadius: "15px"}} >
												<Card.Title style={{fontWeight: 600}}>{datal.name ? datal.name : datal.course_id }</Card.Title>
												<div >
												<Card.Subtitle className="mb-2 mt-20  text-muted " style={{fontSize: "14px"}}><p className="fontwt mb-1">Schedule</p> { moment(datal.start_date).format('MMM DD') } - { moment(datal.end_date).format('MMM DD') }</Card.Subtitle>
												<Card.Subtitle className="mb-2 mt-20  text-muted " style={{fontSize: "14px"}}><p className="fontwt mb-1">Slot</p> { moment(datal.start_time , 'HH:mm:ss').format('HH:mm') } - { moment(datal.end_time , 'HH:mm:ss').format('HH:mm') } </Card.Subtitle> 
												</div> 
												{/* @TODO : FIX Time slot binding */}
													<div className="row my-btns mt-25">
													<div  className=" col-sm-6" onClick={()=> {
														if (role != 'STUDENT') {
															startClass(datal)
														}else if(datal.signed_contract){
															moveToClass(datal)
														}else{
															moveToSignContract(datal)
														}
													}} >
														<b className="btn">{role != 'STUDENT' ? 'Start' : (
															<>
															{
																datal.signed_contract == true ? 'Join Class 1' : 'Sign Contract' 
															}
															</>
														)}</b>
													</div>
													{
															role != 'STUDENT' ? (
																<Dropdown className=" col-sm-6">
																<Dropdown.Toggle variant="" id="dropdown-basic" className="btn ">
																	<span >More  <i className="fas fa-ellipsis-v "></i></span>
																</Dropdown.Toggle>
																<Dropdown.Menu className="read-status">
																<Dropdown.Item href={`/update-class/${datal.id}`}><span>Edit</span></Dropdown.Item>
																</Dropdown.Menu>
															</Dropdown>
															) : ''
														}
													</div>
											</Card.Body>
										</Card>
									</div> 
							))
						}


					</div>
				</Container>

					</> : ''
				}
				{
				role != 'STUDENT' &&	asCoTeacherCLassData && asCoTeacherCLassData.length > 0 ? <>				
				<Container fluid className="w-90per">				
					<h2 className="pageTitle mt-50 text-left text-black">AS CO-TEACHER</h2>	
					<div className="row ">
						{
							asCoTeacherCLassData && asCoTeacherCLassData.map((datal, ranIndex) => (
									<div key={ranIndex} className="col-md-4 col-sm-6 col-xs-12">
										<Card className="course-card shadow-box">
											<Card.Body >
												<Card.Title style={{fontWeight: 600}}>{datal.name ? datal.name : datal.course_id }</Card.Title>
												<Card.Subtitle className="mb-2 mt-20 text-muted " style={{fontSize: "14px"}}><p className="fontwt mb-1">Schedule</p> { moment(datal.start_date).format('MMM DD') } - { moment(datal.end_date).format('MMM DD') }</Card.Subtitle>
												<Card.Subtitle className="mb-2 mt-20 text-muted " style={{fontSize: "14px"}}><p className="fontwt mb-1">Slot</p> { moment(datal.start_time , 'HH:mm:ss').format('HH:mm') } - { moment(datal.end_time , 'HH:mm:ss').format('HH:mm') } </Card.Subtitle>  {/* @TODO : FIX Time slot binding */}
													<div className="d-flex justify-content-between">
													<div  style={{width: "100%"}} className="my-btns" onClick={()=> {
														moveToClass(datal)
													}} >
														<b className="btn">Join Class</b>
													</div>
													</div>
											</Card.Body>
										</Card>
									</div>  
							))
						}


					</div>
				</Container>
					</> : ''
				}
			</Container>
		</LoadingOverlay>
	);
};


export default classMain;
