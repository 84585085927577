const initialState = {
    messageArr: [],
    studentArr: [],
    message: "This is sample message"
    
}

function rootReducer(state = initialState, action) {
    var array = [];
    if(action.type == "MESSAGE") {
        array = state.messageArr;
        array.push(action.payload)
    }
    switch (action.type) {
        case 'MESSAGE_ARRAY':
            return Object.assign({}, state, {
                messageArr: action.payload,
              });
        case 'MESSAGE':
            // var array = [];
            // array = state.messageArr;
            // array.push(action.payload)
            return Object.assign({}, state, {
                messageArr: array,
            });
        case 'STUDENT_ARRAY':
            return Object.assign({}, state, {
                studentArr : action.payload,
            })
        default:
            return state;
    }
}

export default rootReducer;