import React, { useState, useContext, useEffect, useReducer } from "react";
import "../start/start-test";
import "./subjective-test.css"
// var Accordion = require('react-bootstrap/');
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { Link } from "react-router-dom";
import Header from '../../../common/header/header'
import { CommonService } from '../../../service/common.service';
import { useAlert } from "react-alert";
import LoadingOverlay from 'react-loading-overlay';
import queryString from 'query-string';
import ConditionalComponent from '../../../components/test/conditional/conditionalComponent'
import SubjectiveComponent from '../../../components/test/subjective/subjectiveComponent'
import ObjectiveComponent from '../../../components/test/objective/objectiveComponent'
import Countdown from 'react-countdown';
import Modal from 'react-bootstrap/Modal'
import * as moment from 'moment'
import { history } from '../../../_helpers/history'
const SubjectiveTest = ({ match,handleCallback }) => {

    const [loading, setLoading] = useState(false);
    const alert = useAlert();
    const [questionType, setQuestionType] = useState('conditional')
    const [questionTypeIndex, setQuestionTypeIndex] = useState(0)
    const [selectedQuestion, setSelectedQuestion] = useState({})
	const [questionNo, setquestionNo] = useState(1)
    const [testDetails , setTestDetails] = useState(0);
    const [remainingTime , setRemainingTime] = useState(null);
    const [testTime , setTestTime] = useState(null);
    const [totalScore, setTotalScore] = useState('');
    const [testName, setTestName] = useState('');
    const [active, setActive] = useState(false);

    const [showQuestion, setShowQuestion] = useState(true);


    const testParamId = match.params?.testId
    const testResultParamId = match.params?.testResultId
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [timeAlert, setAlert] = useState(false);
    const alertClose = () => setAlert(false);
    const alertShow = () => setAlert(true);
    
    useEffect(() => {
        setLoading(false);
        getTestDetail()
        
    }, []);

    const getTestDetail = () => {
        try {
            CommonService.httpGetService(`admin-api/course-test/details?id=${testParamId}`)
            .then(resData => {
                console.log(resData)
                let count = 0
                for (const key in resData.type_questions) {
                    if (resData.type_questions.hasOwnProperty(key)) {
                        const question = resData.type_questions[key];
                        for (const questionKey in question.data) {
                            if (question.data.hasOwnProperty(questionKey)) {
                                const element = question.data[questionKey];
                                count = count+1
                                element.question_no = count
                            }
                        }
                    }
                }
                setTestDetails(resData)
                setTestName(resData?.name)
                let subjectiveTot = 0;
                let objectiveTot = 0;
                let conditionalTot = 0;
                // type_questions.objective.data[""0""].marks
                resData.type_questions?.subjective?.data.forEach((data) => {
                    subjectiveTot += data?.marks;
                })
                resData.type_questions?.objective?.data.forEach((data) => {
                    objectiveTot += data.marks;
                })
                resData.type_questions?.conditional?.data.forEach((data) => {
                    conditionalTot += data.marks;
                })
            setTotalScore(objectiveTot + conditionalTot + subjectiveTot);
               let  questions = Object.values(resData.type_questions)
               if (questions[0] && questions[0].data[0]) {
                   setSelectedQuestion(questions[0].data[0])
                   setquestionNo(1)
                   setQuestionType(questions[0].data[0].question_type)
                   testResult(resData.duration_in_min)
               }
               
               
            }, error => {
            })
        }
        catch {
            alert.error('Something went wrong..')
        }
    }

    const testResult = (testTimeInMinutes) => {
        try {
            CommonService.httpGetService(`admin-api/user-test/result?id=${testResultParamId}`)
            .then(data => {
            //   setTotalScore(data[0].totalumarks_obtained)
              var startTimeDiff = moment().diff(moment(data[0].start_timestamp),"seconds")
             var  timeDiff = Number(Number(""+moment.duration(testTimeInMinutes, 'minutes'))/1000 - Number(startTimeDiff))
             timeDiff = Number(""+moment.duration(timeDiff, 'seconds'))/60000
             console.log('Time Remaining (in minutes) : ',timeDiff)
             setRemainingTime(Date.now() + 60000*(timeDiff ? timeDiff : 0))
             setTestTime(Date.now() + 60000*(testTimeInMinutes))
            }, error => {
                alert.error(error)
                setLoading(false);
            })
        }
        catch {
            alert.error("Please check all the valid details")
        }
    }

    const submitTest = () => {
        try {
            let reqObj = {
                "id": testResultParamId
            }
            CommonService.httpPostService(`admin-api/user-test/submit`,reqObj)
            .then(data => {
                handleClose()
                handleCallback(testResultParamId)
                // history.push(`/pass-result/${testResultParamId}`);
            }, error => {
                handleClose()
                alert.error(error)
                setLoading(false);
            })
        } catch (error) {
            alert.error('Something went wrong.')
        }
    }

    const handleLoader = (status) => {
        setLoading(status)
    }

    const skipQuestion = (selectedQuestion,testDetails,questionType,questionTypeIndex,questionNo) => {
        let questions = testDetails.type_questions[questionType].data
        let nextQuestion = questions[questionTypeIndex+1]
        if (nextQuestion) {
            setQuestionTypeIndex(questionTypeIndex+1)
        }
        if (nextQuestion == undefined) {
            if (questionType == 'objective' && testDetails.type_questions['conditional']) {
                questions = testDetails.type_questions['conditional'].data
                questionType = 'conditional'
                nextQuestion = questions[0]
                setQuestionTypeIndex(0)
            }
        }
        setShowQuestion(false)
        if (nextQuestion) {
            setquestionNo(questionNo+1)
            setSelectedQuestion(nextQuestion)
            setQuestionType(nextQuestion.question_type)
            setShowQuestion(true)
        }else{
            alert.success("All questions attempted please end test.")
        }
        
    }
    


    const accordionHtml = (quesData,type) => {
        return (
        
            <Accordion>
            <Card >
            <Card.Header>
                <Accordion.Toggle as={Button} variant="link" >
                <span className="quesNum"> 
                {/* {quesData.length}   */}
                {
                    type == 'Objective' ? (
                        <span>Multiple Choice Questions</span> 
                    ) : ''
                }
                {
                    type == 'Conditional' ? (
                        <span>True/False Questions</span>
                    ) : ''
                } </span>
                </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse >
                <Accordion >
                    {
                        quesData && quesData.map((quesDatal, index) => (
                        <ul key={index}  className="quesUl p-0 m-0" style={{ boxShadow: "none" , listStyle: "none" }}>
                        <li className="lectureHeader"  >
                            <Accordion.Toggle as={Button} variant="link" className="btn-ques">
                               
                            <li  key={index} className="ques_li d-flex d-flexWrap flex-v-center" > <span dangerouslySetInnerHTML={{__html: quesDatal.question}}  onClick={() => {
                                    setQuestionType(quesDatal.question_type)
                                    setQuestionTypeIndex(index)
                                    setSelectedQuestion(quesDatal)
                                    setShowQuestion(false)
                                    setShowQuestion(true)
                                 }}></span> <span className="weightage">+{quesDatal.marks}</span></li>
                            </Accordion.Toggle>
                        </li>
                        <Accordion.Collapse style={{ padding: 0 }}>
                            <div style={{ padding: 0 }} >
                                <div className="border-bottom" >
                                </div>
                            </div>
                        </Accordion.Collapse>
                    </ul>
                    ))
                    }
                </Accordion>
            </Accordion.Collapse>
        </Card> 
        </Accordion>
        )
    }


    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Loading...'
            className="loaderStyle"
        >
            <div className="containerBox">
                {/* <Header></Header> */}

                <div className="contentBox d-flex">
                    {/* LeftSidebar */}
                    <div className={`questionSection transition ${active == true ? 'slideLeft transition' : ''}`}>
                        <div className="qsHeading d-flex flex-v-center mb-5">
                            <span className="quesNum text-success">{testName} </span>
                            <i onClick={() => {setActive(!active)}} className="fas fa-times transition text-blue" aria-hidden="true"></i>
                            </div>
                        <div className="questionList">
                                {
                                    testDetails && testDetails.type_questions ? (
                                        testDetails.type_questions['subjective'] && testDetails.type_questions['subjective'].data != undefined ? (
                                            accordionHtml(testDetails.type_questions['subjective'].data,'Subjective')
                                        ) : ''
                                    ) : ''
                                }

                                {
                                    testDetails && testDetails.type_questions ? (
                                        testDetails.type_questions['objective'] && testDetails.type_questions['objective'].data != undefined ? (
                                            accordionHtml(testDetails.type_questions['objective'].data,'Objective')
                                        ) : ''
                                    ) : ''
                                }

                                {
                                    testDetails && testDetails.type_questions ? (
                                        testDetails.type_questions['conditional'] && testDetails.type_questions['conditional'].data != undefined ? (
                                            accordionHtml(testDetails.type_questions['conditional'].data,'Conditional')
                                        ) : ''
                                    ) : ''
                                }
                        </div>
                        <div className="qsHeading mb-2  bg-white p-20 bdr-radius-5">                            
                            <span className="quesNum">
                            <b className="color-blue text-bold text-size-16">{testDetails?.total_questions}</b>
                              Questions</span>
                            <span className="quesNum">Total Score: 
                            <b className="color-blue text-bold text-size-16">{testDetails?.marks} {totalScore}</b>
                            </span>
                        </div>
                        {/* <div className="answerStatus bg-white p-20">
                            <h4 className="color-blue text-bold text-size-16">Summary</h4>
                            <div className="lside">
                                <div className="answered text-center">
                                    <span className="grbox box"> 10 </span>
                                    <span className="ansStatusTxt"> Answered</span>
                                </div>
                                <div className="answered text-center">
                                    <span className="yebox box"> 0 </span>
                                    <span className="ansStatusTxt"> Marked</span>
                                </div>
                            </div>
                            <div className="rside">
                                <div className="answered text-center">
                                    <span className="rebox box"> 9 </span>
                                    <span className="ansStatusTxt"> Not Answered</span>
                                </div>
                                <div className="answered text-center">
                                    <span className="blbox box"> 3 </span>
                                    <span className="ansStatusTxt"> Not Visited</span>
                                </div>
                            </div>
                        </div> */}
                           {/* <div className="endTest show-mobile d-none">                            
                                    <Button className="endtestBtn" variant="danger" onClick={handleShow}>End Test  <i className="fas fa-arrow-circle-right"></i></Button>   
                          </div>  */}
                    </div>                                
                    {/* MainBody */}
                    <div className="presentationSection m-0 p-20">                           
                        <div className="testBodyHeader">
                            <div>
                                    <i onClick={() => {setActive(!active)}} className={`fas fa-bars transition fa-2x color-blue ${active == true ? 'rotate transition' : ''}`} aria-hidden="true"></i>
                            </div>
                            {/* <div className="testTiming ml-2">                            
                                    {testTime != null ? 
                                        (
                                            <div className="remainingTimeBox text-center">
                                                <span className="remainingTime" > Remaining Time</span>
                                                <div className="remainingTimeCounter"><i className="fas fa-stopwatch " aria-hidden="true"></i> <Countdown date={remainingTime  } onComplete={alertShow}/></div>
                                            </div>
                                        ) : ''
                                    }
                                        {testTime != null ? 
                                        (
                                            <div className="totalTimeBox d-flex flex-h-spaceBetween flex-v-center">
                                                <p className="m-0" >Total Time</p>
                                                <p className="m-0 totalcountDown" ><Countdown date={testTime}  autoStart={false}/></p>
                                            </div>
                                        ) : ''
                                    }
                            </div> */}
                            <div>
                                                        
                            {testTime != null ? 
                            (
                                <div className="remainingTimeBox text-text-center">
                                                <div className="remainingTimeCounter"><i className="fas fa-stopwatch " aria-hidden="true"></i> <Countdown date={remainingTime  } onComplete={alertShow}/></div>
                                                {/* <small className="totalTime" > Total Time: 20 mins</small> */}
                                            </div>
                            ) : ' '
                            }

                            </div>
                            <div className="endTest hide-mobile">                            
                                    <Button className="endtestBtn" variant="danger" onClick={handleShow}>End Test  <i className="fas fa-arrow-circle-right"></i></Button>   
                            </div>
                        </div>

                      {
                         testDetails && questionType == 'subjective' && showQuestion ? (
                            <SubjectiveComponent testResultId={testResultParamId}  selectedQuestion={selectedQuestion} testDetails={testDetails} questionType={questionType} questionIndex={questionTypeIndex} questionNo={questionNo} handleSkip={skipQuestion} handleLoading={handleLoader}  />
                          ) : (
                            null
                          )
                      }

                      {
                          testDetails && questionType == 'objective' && showQuestion  ? (
                            <ObjectiveComponent testResultId={testResultParamId}  selectedQuestion={selectedQuestion} testDetails={testDetails} questionType={questionType} questionIndex={questionTypeIndex} questionNo={questionNo} handleSkip={skipQuestion} handleLoading={handleLoader}/>
                          ) : (
                            null
                          )
                      }

                      {
                          testDetails && questionType == 'conditional' && showQuestion  ? (
                            <ConditionalComponent testResultId={testResultParamId}  selectedQuestion={selectedQuestion} testDetails={testDetails} questionType={questionType} questionIndex={questionTypeIndex} questionNo={questionNo} handleSkip={skipQuestion} handleLoading={handleLoader} />
                          ) : (
                            null
                          )
                      }
                      <div className="endTest show-mobile d-none">                            
                                    <Button className="endtestBtn" variant="danger" onClick={handleShow}>End Test  <i className="fas fa-arrow-circle-right"></i></Button>   
                        </div> 
                    </div>
                    
                    {/* RightSidebar */}
                    <div className="testStatus">
                        {/* <div className="statusHeading">  */}
                        <div className="testHeading mb-4">
                            
                            <span className="quesNum">{testDetails?.total_questions} Questions</span>
                            <span className="quesNum">Total Score: {testDetails?.marks} {totalScore}</span>
                        </div>
                        {testTime != null ? 
                        (
                            <div className="timeStatus">
                            <span className="time-left">Remaining Time</span>
                            <span className="timer"><Countdown date={remainingTime  } onComplete={alertShow}/></span>
                        </div>
                        ) : ''
                     }
                        {testTime != null ? 
                        (
                            <div className="totTime">
                            <span className="time-left">Total Time</span>
                            <span className="time-left"><Countdown date={testTime}  autoStart={false}/></span>
                        </div>
                        ) : ''
                     }
                       
                       
                        {/* <div className="answerStatus">
                            <span className="time-left">Summary</span>
                            <div className="lside">
                                <div className="answered">
                                    <span className="grbox"> 10 </span>
                                    <span className="ansStatusTxt"> Answered</span>
                                </div>
                                <div className="answered">
                                    <span className="yebox"> 0 </span>
                                    <span className="ansStatusTxt"> Marked</span>
                                </div>
                            </div>
                            <div className="rside">
                                <div className="answered">
                                    <span className="rebox"> 9 </span>
                                    <span className="ansStatusTxt"> Not Answered</span>
                                </div>
                                <div className="answered">
                                    <span className="blbox"> 3 </span>
                                    <span className="ansStatusTxt"> Not Visited</span>
                                </div>
                            </div>
                        </div> */}
                        {/* <span className="listHeading">General Instructions</span>
                        <ul className="genInsList">
                            <li className="Inst_li">Test Duration: 3 hrs</li>
                            <li className="Inst_li">Make sure you check test ID</li>
                            <li className="Inst_li">Click submit after every answer</li>
                            <li className="Inst_li">Click ? for any help or doubt</li>
                        </ul> */}

                        {/* <div className="endTest">
                            
                                <Button className="endtestBtn" variant="danger" onClick={handleShow}>End Test  <i className="fas fa-arrow-circle-right"></i></Button>
                                
                        </div> */}

                        <Modal  show={show}
                                onHide={handleClose}
                                backdrop="static"
                                keyboard={false}>
                            <Modal.Header closeButton>
                                <Modal.Title>Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Do you want to end this test?
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    No
                                </Button>
                                <Button variant="primary" onClick={submitTest}> Yes</Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal  show={timeAlert}
                                onHide={alertClose}
                                backdrop="static"
                                keyboard={false}>
                            <Modal.Header >
                                <Modal.Title>TIMEOUT</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                               Time's up! You cannot continue test.
                            </Modal.Body>
                            <Modal.Footer>
                                {/* <Button variant="secondary" onClick={alertClose}>
                                    No
                                </Button> */}
                                <Button variant="primary" onClick={submitTest} > Ok</Button>
                            </Modal.Footer>
                        </Modal>

                    </div>
                </div>
            </div>
        </LoadingOverlay>
    );
};


export default SubjectiveTest;
