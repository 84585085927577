import { hot } from "react-hot-loader";
import React, { useEffect,useState } from "react";
import AppRouter from "./routes";
import { positions, Provider } from "react-alert";
import AlertTemplate from "./components/react-alert-template-basic/dist/cjs/react-alert-template-basic";
// import { Provider as AuthProvider } from '../src/context/AuthContext';
import * as Sentry from "@sentry/react";
import "./App.css"
const options = {
	timeout: 5000,
	position: positions.TOP_CENTER
};
const style = {
	backgroundColor: '#151515',
	color: 'black',
	padding: '10px',
	textTransform: 'uppercase',
	borderRadius: '3px',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
	fontFamily: 'Arial',
	width: '600px',
	boxSizing: '6border-box'
}
function FallbackComponent() {
	return <div>An error has occurred</div>;
}

  const myFallback = <FallbackComponent />;
const App = () => {
	const [isCookieEnabled,setCookieEnabledStatus] = useState(false)
	useEffect(()=> {
		if(window.navigator.cookieEnabled){
			setCookieEnabledStatus(window.navigator.cookieEnabled)
		}
	},[])
	return (
		<Sentry.ErrorBoundary fallback={myFallback}>
			<Provider template={AlertTemplate}  {...style} {...options}>
				{/* <AppRouter /> */}
				<AppRouter />
				{!isCookieEnabled ? 
					<div className="cookiecard">
						<div className="cookieContainer">
							<div className="cookieContent">
								<p className="m-0">This website uses cookies in order to offer you the best user experience on our website. Please go to your browser's setting and "<strong>allow cookies</strong>."
               					 </p>
								{/* <a href="#">More Information</a> */}
							</div>
							{/* <button>Accept</button> */}
						</div>
					</div> : null}
			</Provider>
		</Sentry.ErrorBoundary>

		//  <AuthProvider>
		//  </AuthProvider> 
	)
	// return <AppRouter/>
};

export default App;
