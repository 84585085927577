import React, { useState, useContext, useEffect, useReducer } from "react";
import "./quizQuestionComponent.css"
import Button from 'react-bootstrap/Button'
import { useAlert } from "react-alert";
import InputGroup from 'react-bootstrap/InputGroup';
import Countdown from 'react-countdown';
import { CommonService } from '../../../service/common.service';
import { Link } from "react-router-dom";
import QuizPassResult from '../../result/quiz/quiz-pass-result';

const QuizQuestionComponent = ({ quizId, resultId }) => {
	const alert = useAlert();

	const [selectedQuestion, setSelectedQuestion] = useState({})
	const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(1)
	const [quizDetails, setQuizDetails] = useState(0);
	const [quizTime, setTime] = useState(null);
	const [questionsArr, setQuestions] = useState([]);
	const [selectedAnswer, setSelectedAnswer] = useState(null)
	const [isCorrectAnswer, setIsCorrectAnswer] = useState(null)
	const [totalAttempted, setTotalAttempted] = useState(0)
	const [totalCorrect, setTotalCorrect] = useState(0)
	const [totalWrong, setTotalWrong] = useState(0)
	const [isFinished, setFinished] = useState(false)
	const [isSubmitted, setIsSubmitted] = useState(null)

	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		getQuizDetails()
	}, []);

	const getQuizDetails = () => {
		try {
			CommonService.httpGetService(`admin-api/course-test/details?id=${quizId}`)
				.then(resData => {
					setQuizDetails(resData)
					let questionsTypeArr = Object.values(resData.type_questions)
					let questions = []
					for (const key in questionsTypeArr) {
						if (questionsTypeArr.hasOwnProperty(key)) {
							const question = questionsTypeArr[key];
							for (const questionKey in question.data) {
								if (question.data.hasOwnProperty(questionKey)) {
									const element = question.data[questionKey];
									element.answer = null
									questions.push(element)

								}
							}
						}
					}
					if (questions.length > 0) {
						setQuestions(questions)
						setSelectedQuestion(questions[0])
						setSelectedQuestionIndex(1)
						console.log(questions)
						setTime(Date.now() + 60000 * (resData.duration_in_min ? resData.duration_in_min : 0))
					}
				}, error => {
				})
		}
		catch {
			alert.error('Something went wrong..')
		}
	}

	const nextQuestion = (currentIndex) => {
		setSelectedQuestionIndex(currentIndex + 1)
		setSelectedQuestion(questionsArr[(currentIndex - 1) + 1])
		setIsCorrectAnswer(null)
		setSelectedAnswer(null)
		setIsSubmitted(null)
	}

	const previousQuestion = (currentIndex) => {
		setSelectedQuestionIndex(currentIndex - 1)
		setSelectedQuestion(questionsArr[(currentIndex - 1) - 1])
		setIsCorrectAnswer(null)
		setSelectedAnswer(null)
		setIsSubmitted(null)
	}

	const checkAnswer = (currentQuestion, choice, questionIndex) => {
		// let totalAttempt = 0
		// if (currentQuestion.selectedAnswer == undefined) {
		// 	totalAttempt = totalAttempted
		// 	totalAttempt++
		// 	setTotalAttempted(totalAttempt)
		// }
		// if (questionsArr[questionIndex - 1]) {
		// 	questionsArr[questionIndex - 1].selectedAnswer = choice
		// }
		// setQuestions(questionsArr)
		// setSelectedQuestion(questionsArr[questionIndex - 1])
		// setSelectedAnswer(choice)
		// if (currentQuestion.answer == choice) {
		// 	setIsCorrectAnswer(true)
		// } else {
		// 	setIsCorrectAnswer(false)
		// }
		
		setLoading(true);
		try {
			setIsSubmitted(null)
			submitAnswer(choice,currentQuestion,questionIndex)
			setLoading(false);
		}
		catch {
			console.log('something went wrong with quiz result');
			setLoading(false);
			
		}

		// if (questionIndex == questionsArr.length) {
		// 	let correct = 0
		// 	let wrong = 0
		// 	for (const key in questionsArr) {
		// 		if (questionsArr.hasOwnProperty(key)) {
		// 			const question = questionsArr[key];
		// 			if (question.selectedAnswer) {
		// 				if (question.answer == question.selectedAnswer) {
		// 					correct++
		// 				} else {
		// 					wrong++
		// 				}
		// 			}
		// 		}
		// 	}
		// 	setTotalCorrect(correct)
		// 	setTotalWrong(wrong)
		// 	setTimeout(() => {
		// 		alert.success(`Quiz Completed`)
		// 		setFinished(true)
		// 	}, 1000);
		// }
	}

	const submitAnswer = (choice,currentQuestion,questionIndex) => {
		setLoading(true);
		try {

			let totalAttempt = 0
			if (currentQuestion.selectedAnswer == undefined) {
				totalAttempt = totalAttempted
				totalAttempt++
				setTotalAttempted(totalAttempt)
			}
			var userData = JSON.parse(localStorage.getItem('user'))
			if (questionsArr[questionIndex - 1]) {
				questionsArr[questionIndex - 1].selectedAnswer = choice
			}
			setQuestions(questionsArr)
			setSelectedQuestion(questionsArr[questionIndex - 1])
			setSelectedAnswer(choice)
			let reqObj = {
				"user_test_result_id": resultId,
				"course_question_id": selectedQuestion.id,
				"user_id": userData.id,
				"answer": choice,
				"type": "QUIZ"
			}
			console.log(reqObj)
			CommonService.httpPostService(`admin-api/user-test`, reqObj)
				.then(resp => {
					setIsSubmitted(true)
					console.log(resp);

					if (questionsArr[questionIndex - 1]) {
						questionsArr[questionIndex - 1].selectedAnswer = choice
					}
					setQuestions(questionsArr)
					
					setSelectedAnswer(choice)
					if (resp.data.marks_obtained > 0) {
						 
						questionsArr[questionIndex - 1].answer = choice
						setSelectedQuestion(questionsArr[questionIndex - 1])
						setIsCorrectAnswer(true)
						setLoading(false);
						 
					} else {
						setIsCorrectAnswer(false)
						setLoading(false);
					}
					if (questionIndex == questionsArr.length) {
						submitQuiz()
						let correct = 0
						let wrong = 0
						for (const key in questionsArr) {
							if (questionsArr.hasOwnProperty(key)) {
								const question = questionsArr[key];
								if (question.selectedAnswer) {
									if (question.answer == question.selectedAnswer) {
										correct++
									} else {
										wrong++
									}
								}
							}
						}
						setTotalCorrect(correct)
						setTotalWrong(wrong)
						setTimeout(() => {
							alert.success(`Quiz Completed`)
							setFinished(true)
						}, 1000);
					}
				}, error => {
					alert.error(error)
					// setLoading(false);
				})

		}
		catch (error) {
			alert.error('Something went wrong..')
		}
	}

	const submitQuiz = () => {
		let reqObj = {
			id : resultId
		}
		let correct = 0
		let wrong = 0
		CommonService.httpPostService(`admin-api/user-test/submit`, reqObj)
				.then(resp => {
					console.log('Quiz Submit Response : ',resp);
				
					for (const key in questionsArr) {
						if (questionsArr.hasOwnProperty(key)) {
							const question = questionsArr[key];
							if (question.selectedAnswer) {
								if (question.answer == question.selectedAnswer) {
									correct++
								} else {
									wrong++
								}
							}
						}
					}
					setTotalCorrect(correct)
					setTotalWrong(wrong)
					setTimeout(() => {
						alert.success(`Quiz Completed`)
						setFinished(true)
					}, 1000);
				}, error => {
					alert.error(error)
				})

	}



	return (

		!isFinished && quizDetails && selectedQuestion ? (<div className="quizContent">
			<div className="qsHeading mb-4">
				<span className="quesNum"><Countdown date={quizTime} /></span>
				<span className="quesNum">Question {selectedQuestionIndex} of {questionsArr.length}</span>
				<span className="quesNum"></span>

			</div>
			<div className="currentQ" style={{minHeight: "228px"}}>
			<span className="areaQues p-1" style={{display : "inline-block"}}>Question :<span className="areaQues p-1" style={{display : "inline-block"}} dangerouslySetInnerHTML={{__html: selectedQuestion.question }}></span></span>
				<div className="option-area">

					{
						selectedQuestion && selectedQuestion.choices && selectedQuestion.choices.map((choice, index) => (
							<InputGroup key={index} className="mb-3">
								<Button
									className={`w-100 quizAnserBtn 
								 ${!isLoading && !selectedQuestion.selectedAnswer ? "btn-outline-secondary" : ''}
								 ${!isLoading && selectedQuestion.selectedAnswer && selectedQuestion.selectedAnswer == choice && selectedQuestion.answer && selectedQuestion.selectedAnswer == selectedQuestion.answer ? "btn-success clrWhite" : ''}
								 ${!isLoading && selectedQuestion.selectedAnswer && selectedQuestion.selectedAnswer == choice &&  selectedQuestion.selectedAnswer != selectedQuestion.answer ? "btn-danger clrWhite" : ''}`
									}
									onClick={e => {checkAnswer(selectedQuestion, choice, selectedQuestionIndex)}}
									variant="outline-secondary"
									disabled={isLoading}
								>
									{isLoading ? 'Loading…' : choice}
									{/* {!isLoading ? choice : ''} */}
								</Button>
							</InputGroup>
						))
					}
				</div>

			</div>

			<div className="d-flex mb-2 justify-content-around">
				{selectedQuestionIndex > 1 ? (
						<span className="quesNum" onClick={e => { previousQuestion(selectedQuestionIndex) }} style={{ cursor: "pointer" }}> <i className="fas fa-chevron-left"></i> Previous  </span>
					) :  (
						<span className="quesNum"><Link to="#" disabled><i className="fas fa-chevron-left"></i>  Previous  </Link></span>
					)}

				{selectedQuestionIndex < questionsArr.length ? (
						<span className="quesNum" onClick={e => { nextQuestion(selectedQuestionIndex) }} style={{ cursor: "pointer" , marginTop: "1.4px" }}>Skip </span>
					) : (
						<span className="quesNum"><Link to="#" style={{marginTop: "1.4px" }} disabled>Skip </Link></span>

					)}

					{selectedQuestionIndex < questionsArr.length ? (
						<span className="quesNum" onClick={e => { nextQuestion(selectedQuestionIndex) }} style={{ cursor: "pointer" }}>Next  <i className="fas fa-chevron-right"></i></span>
					) : (
						<span className="quesNum"><Link to="#" disabled>Next  <i className="fas fa-chevron-right"></i></Link></span>

					)}

			</div>
			<div className="submitBtnRow">
				{
					(isSubmitted == true && isCorrectAnswer == true) || (selectedQuestion.selectedAnswer && selectedQuestion.answer && selectedQuestion.selectedAnswer == selectedQuestion.answer) ? (
					<>	<span className="right-ans-msg">Correct answer, Keep it up!</span> <br/> </>
					) : ''
				}
				{
					(isSubmitted == true && isCorrectAnswer == false) || (selectedQuestion.selectedAnswer && selectedQuestion.answer && selectedQuestion.selectedAnswer != selectedQuestion.answer) ? (
					<>	<span className="wrong-ans-msg">Oops! Wrong answer, Try harder</span> <br/> </>
					) : ''
				}
			</div>
		</div>) : (
				<>
					{isFinished == true ? (
						<QuizPassResult totalAttempted={totalAttempted} totalQuestion={questionsArr.length} totalCorrect={totalCorrect} totalWrong={totalWrong} />
						// <div className='container'>
						// 	<div className="row">
						// 		Total Attempted : {totalAttempted} out of {questionsArr.length}
						// 	</div>
						// 	<div className="row">
						// 		Correct : {totalCorrect}
						// 	</div>
						// 	<div className="row">
						// 		Wrong/Not Attempted : {totalWrong}
						// 	</div>
						// </div>
					) : ''}
				</>
			)
	);
};


export default QuizQuestionComponent;
