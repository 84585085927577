import React, { useState, useContext, useEffect, useReducer } from "react";
import "./testComponent.css";
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import { CommonService } from '../../service/common.service';
import { useAlert } from "react-alert";
import { history } from '../../_helpers/history'
import Countdown from 'react-countdown';
import '../../pages/home/Home.css';
import moment from 'moment';
import SubjectiveComponent from "./subjective/subjectiveComponent";
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import SubjectiveTest from "../../pages/test/subjective/subjective-test";
import queryString from 'query-string';
import PassResult from "../result/pass/pass-result";

const TestComponent = ({courseId, classId , testId,selectedTest,selectedLecture}) => {
	const alert = useAlert();
	const [testModalShow, settestModalShow] = useState(false);	
	const [isMaximized, setIsMaximized] = useState(true);
	const [testEnded, setTestEnded] = useState(false);
	const hideTestModal = () => settestModalShow(false);
	const [ match , setMatch ] = useState({});
	const queryParams = queryString.parse(location.search);
	const [isTestImageShow, setIsTestImageShow] = useState(false)
	
	const [testResultId, setTestResultId] = useState(queryParams?.testResultId);
	const onClickSubmit = () => {
		try {
			var userData = JSON.parse(localStorage.getItem('user'))
			let reqObj = {
				"user_id": userData.id,
				"course_test_id": testId,
				"class_id": classId,
				"lecture_id" : selectedLecture.id,
				"tenant_id": 1,
				"last_modified_by": userData.id
			}
			console.log(reqObj)
			CommonService.httpPostService(`admin-api/user-test/start`,reqObj)
			.then(resp => {
				setTestResultId(resp.data.id)
				let obj = {
					testId : testId,
					testResultId : resp.data.id,
				}
				setMatch({params: obj})
				settestModalShow(true);
				setIsMaximized(true)
				setIsTestImageShow(true)
				// window.open(`/subjective-test/${testId}/${resp.data.id}`,'_blank')
				// history.push(`/subjective-test/${testId}/${resp.data.id}`);
				history.push({
					pathname: location.pathname,
					search: `channel_name=${queryParams.channel_name}&${new URLSearchParams(obj).toString()}`
				})
				// search: "?" + new URLSearchParams({clientId: clientId}).toString()
			}, error => {
				
				alert.error(error)
			})
	}
	catch {
		alert.error('Something went wrong..')
	}
	}
	const checktestStatus = () => {
		alert.info('Resuming Test')
		CommonService.httpGetService(`admin-api/user-test/check-staus?user_test_result_id=${testResultId}&lecture_id=${selectedLecture.id}`)
			.then(resp => {
				if(resp.canAttempt){
					let obj = {
						testId : testId,
						testResultId : testResultId,
					}
					setMatch({params: obj})
					settestModalShow(true);
					setIsMaximized(true)
					setIsTestImageShow(true)
				}else{
					alert.error('Cannot Resume test. Please Start New Test.')
				}
			}, error => {
				alert.error(error)
			})
		
	}
	

	const handleCallback = () => {
		setTestEnded(true)
	}

	const handleClose = () => {
		setTestEnded(false)
		settestModalShow(false)
		setIsMaximized(false)
		setTestResultId(null)
		history.push({
			pathname: location.pathname,
			search: `channel_name=${queryParams.channel_name}`
		})
		queryParams = null
		}

		const onBackPresend=(value)=>{
			setIsMaximized(value)
		}

	return (
		// <div className="containerBox">
		
			<div className="startScreen">

{/* {data.lectures.test.map((testData, index) => ( */}
				<>

						{
							isTestImageShow ?
							<div className="testImageContainer">
								<div>
									<img src="https://webstockreview.net/images/office-clipart-security-guard-3.png" height="300px" />
								</div>
								<h3 class="text-success">It's Exam Time Again</h3>
								{/* <h4>Please wait for further instruction from teacher.</h4> */}
							</div>
							: 
							<div>
							<div className="testDetails">
					<div className="ltdetails">
						<span className="testName fontwt mb-1">{selectedTest?.name}</span>
						<span className="testDuration mb-1">Duration <span className="fontwt">{selectedTest?.duration_in_min} min</span></span>
						{
							selectedLecture && selectedLecture.test_enabled_till_timestamp ? (<span className="testDuration mb-1">Due Date <span className="fontwt"> {moment(selectedLecture?.test_enabled_till_timestamp).format('dddd, MMMM Do YYYY')} </span></span>) : ''
						}
						
					</div>
					<div className="rtdetails">
						<span className="systemCompatible"><i className="fas fa-check-circle" style={{ color: "#A5C75F" }}></i> System is compatible for taking this test </span>
						
						
						{
							selectedLecture.active_status == 'enabled' && selectedLecture.test_enabled_till_timestamp  ? (
								<span className="timer-space d-flex" style={{flexDirection: "column"}}>
								<span className="timer-placeholder"> HH : MM : SS </span>
								<Countdown date={new Date(selectedLecture.test_enabled_till_timestamp.split(" ").join("T"))} daysInHours={true}/>
								</span>
							) : ''
						}
						
					<div className="d-flex">
					
					{
							(testId != null  ) ? <Button onClick={()=>onClickSubmit()} className={selectedLecture.active_status == "enabled" ? "  grnColor" : " greyColor" } variant="success" disabled={selectedLecture.active_status == "enabled" ? false: true}>Start {testResultId ? 'New' : ''} Test  <i className="fas fa-arrow-circle-right"></i></Button> : null
						}
						{
							(testId != null && testResultId  ) ? <Button onClick={()=>checktestStatus()} className={selectedLecture.active_status == "enabled" ? "  infoColor " : " greyColor " } variant="info" disabled={selectedLecture.active_status == "enabled" ? false: true}>Resume Test  <i className="fas fa-arrow-circle-right"></i></Button> : null
						}
					</div>
					</div>
				</div>
				<div className="instructionDetails">
					<div className="generalIns">
						<span className="listHeading">General Instructions</span>
						{selectedTest && selectedTest.instructions ? (<span className="typeoftest d-flex" dangerouslySetInnerHTML={{__html: selectedTest.instructions}}></span>) : ''}
						

						{/* <span className="listHeading mt-4">The test will have following types of questions</span>
						<ul className="genInsList">
							<li className="Inst_li">15 MCQs</li>
						</ul> */}
					</div>

					<div className="otherIns">
						<span className="listHeading">Help and Support</span>
						<div className="typeoftest">Please contact the test administrator. <br />
							<span className="fontwt">{selectedTest?.help_and_support?.name}</span> at the given email address : <br />
							<a href={`mailto:${selectedTest?.help_and_support?.email}`}>{selectedTest?.help_and_support?.email}</a></div>
					</div>
				</div>
							</div>
						}
				{
				testModalShow ?	<div className=" dBlock">
					<div  className={isMaximized ? "testMaximizedBox" : "testMinimizedBox"} >
						<div className="header" >
							<div >
								{ isMaximized ?
									<h4>{selectedTest?.name}</h4>
								: 
								<>
								{!testEnded ? 
								<div>
									<h5 className=" m-0">Test is going on</h5>
									<small><Countdown className="text-danger" date={new Date(selectedLecture.test_enabled_till_timestamp.split(" ").join("T"))} daysInHours={true}/></small>
									</div>
									:null
								}
								</>
								}
							</div>						
							{!testEnded ? <span className="btns" onClick={()=>{ setIsMaximized(!isMaximized)}} >
								{ isMaximized ? "Back to Class" 	:  "Back to Test"}
							</span> : 
							<div>
							{/* <span className="btns" onClick={()=>{ handleClose()}} >
								Close
							</span> */}
							<span className={isMaximized ? " " : "btns"} onClick={()=>{ setIsMaximized(!isMaximized)}} >
								{ isMaximized ? null :  "Back to Result"}
							</span>
							</div>
						}
						</div>
						<div className="testBody">
							{
							!testEnded ?	<SubjectiveTest  match={match} handleCallback={handleCallback}/>
							: <PassResult match={{params: {
								testId : testResultId
							}}} closeResult={()=> {
								handleClose()
							}} onMinimize={onBackPresend}/>
							}
						</div>
					</div>
					</div> : null
				}
				</>
					{/* ))
				} */}
			</div>
		
		// </div>
	);
};

export default TestComponent;
