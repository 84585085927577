import React, { useEffect, useState } from 'react';
import socketIOClient from "socket.io-client";
import TeacherIcon from '../../../assets/security-officer.png'
import * as kurentoUtils from 'kurento-utils'
const StudentKurento = React.memo(function StudentKurento(props) {
    let localSocket = null
    let isPresenter = false;
    let rtcPeer;
    const [isVideo, setIsVideo] = useState(true);
    const [rtcConnection,setRTCConnection] = useState(null)
    const [socket,setSocket] = useState(null)
    useEffect(() => {
        localSocket = socketIOClient(process.env.stream_url);
        setSocket(localSocket)
        localSocket.on("message", (message) => {
            console.log("Message received: " + message.event);
            switch (message.event) {
                case "ready":
                    if(!isPresenter) {
                        receiveVideo();
                    }
                    break;
                case "newParticipantArrived":
                    console.log(`${message.username} has joined the session`);
                    break;
                case "receiveVideoAnswer":
                    onReceiveVideoAnswer(message.sdpAnswer);
                    break;
                case "candidate":
                    addIceCandidate(message.candidate);
                    break;
                case "stopMedia":
                    stopVideo(message,false,false)
                    break;
                case "startMedia":
                    stopVideo(message,true,false)
                    break;
            }
        });

    }, [])

    useEffect(()=> {
        joinRoom()
    },[])

    const joinRoom =  () => {
        const message = {
            event: "joinRoom",
            userName: props.userData.name,
            roomName: props.roomName,
          };
        sendMessage(message);
    }

    const stopVideo = (message,status,isRejoin)=>{
        debugger
        console.log('Stop media event : ',message)
        if(status){
            joinRoom()
        }else if(!status && rtcPeer) {
                rtcPeer.dispose()
        }
        if(isRejoin){
            status = true
            joinRoom()
        }
        setIsVideo(status)
    }


    function receiveVideo() {
        // pPresenterName.innerText = `${presenterName} is presenting...`
        var  videoBroadcast = document.getElementById("broadcast");
        var options = {
          remoteVideo: videoBroadcast,
          onicecandidate: onIceCandidate,
        };
      
        rtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options, function (
          err
        ) {
          if (err) {
            return console.error(err);
          }
          this.generateOffer(onOffer);
        });
        setRTCConnection(rtcPeer)
      
        var onOffer = function (err, offer, wp) {
          console.log("sending offer");
          var message = {
            event: "processOffer",
            roomName: props.roomName,
            sdpOffer: offer,
          };
          sendMessage(message);
        };
      
        function onIceCandidate(candidate, wp) {
          console.log("sending ice candidates");
          var message = {
            event: "candidate",
            roomName: props.roomName,
            candidate: candidate,
          };
          sendMessage(message);
        }
        
      }


    function onReceiveVideoAnswer(sdpAnswer) {
        rtcPeer.processAnswer(sdpAnswer);
    }

    function addIceCandidate(candidate) {
        rtcPeer.addIceCandidate(candidate);
    }

    // utilities
    function sendMessage(message) {
        console.log("sending " + message.event + " message to server",message);
        if(socket){
            socket.emit("message", message);
        }else if(localSocket){
            localSocket.emit("message", message);
        }else{
            console.log('Not able to send message due to socket is undefined ')
        }
    }

    return (
        <>
            {/* <div id="meetingRoom">
                <video id="broadcast" autoPlay></video>
                <p id="presenterName"></p>
                <ul id="viewers"></ul>
            </div> */}

        <video style={{ width: "100%" }}
         className={`${isVideo ? '' : 'd-none'}`}
          id="broadcast"  muted autoPlay playsInline></video>
         {isVideo ?  <span className="videoReload"  onClick={()=> {
              stopVideo('',false,true)
          }}>
              RELOAD
          </span> : null}
        <div className={`'usr-avatar' ${isVideo ? 'd-none' : ''}`} style={{ padding: "10px", margin: "0 auto", display: "table" }}>
            <img src={TeacherIcon} />
        </div>
        {
            props.teacherData.length > 0 ? (
                <span style={{ margin: "0 auto", display: "table", fontSize: "20px", fontWeight: "bold" }}>{props.teacherData[0].first_name}</span>
            ) : null
        }


    </>
    )
});

export default StudentKurento;