import React, { useState, useContext, useEffect } from "react";
// import "./view-lecture.css";
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container';
import Header from '../../../common/header/header'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {CommonService} from '../../../service/common.service';
import { useAlert } from "react-alert";
import {history} from '../../../_helpers/history'
import NotFoundIMg from '../../../assets/question-mark.png'
import LoadingOverlay from 'react-loading-overlay';
import '../view/view-user.css';
import CertificateImg from '../../../assets/icons/certificate.png'
import TestImg from '../../../assets/icons/test.png'
import ContractImg from '../../../assets/icons/contract.png'
import TimetableImg from '../../../assets/icons/timetable.png'
import ActivitiesImg from '../../../assets/icons/activities.png'
import * as FileSaver from 'file-saver';


const viewUser = ({match}) => {
const [isAllDownload, setIsAllDownload] = useState(false);

    const alert = useAlert();
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [dltShow, setdltShow] = useState(false);
    const delethandleClose = () => setdltShow(false);
    const [filteredData, setFilteredData] = useState([]);
    const [showDocs, setShowDocs] = useState(false);



    const [dta, setData] = useState([]);
    const [dltId, setdltId] = useState();

    const [selectedCourseId,setSelectedCourseId] = useState()
    const [courseData, setCourseData] = useState([])
    const [classList, setClassList] = useState([])
    const [newClassId, setNewClassId] = useState()
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [showMoveStudentModal, setShowMoveStudentModal] = useState(false);

    const [userData, setUserData] = useState();
    const [certificateUrl , setCertificateUrl] = useState("")
    const [contractUrl, setContractUrl] = useState("")
    const [answerSheetUrl, setAnswerSheetUrl] = useState("")
    const [username, setUsername] = useState("")
    const [actBuffer, setActBuffer] = useState("")
    const [userId, setUserId] = useState()
    const [showCertiLoader, setShowCertiLoader] = useState(false)
    const [showActLoader, setShowActLoader] = useState(false)
    const [showTestLoader, setShowTestLoader] = useState(false)
    const [showContractLoader, setShowContractLoader] = useState(false)
    const [content, setContent] = useState("")
    const handleCloseMoveModal = () => setShowMoveStudentModal(false);

    const handleShowMoveModal = async (datal) => {
        setSelectedStudent(datal,getCourses(datal));
        // getCourses(datal)
        // setShowMoveStudentModal(true)
    }

    useEffect(() => {
        setTimeout(() => {
            setContent("")
        }, 9999)
    }, [content])

    const getUsers = async () => {
        setLoading(true)
         
        CommonService.httpGetService(`user/user`)
        .then(jsonResponse => {
            setData(jsonResponse);
            setFilteredData(jsonResponse);
            setLoading(false)
            },error =>{
                alert.error(error)
                setLoading(false)
        })
    }

    const filterData = (value) => {
        let tempArr = Object.assign([], dta)
        if (value.length >= 3) {
            let upperCaseFilter = value.toUpperCase()
            let filterDataArr = tempArr.filter(
                option => {
                    if (
                        (
                            option.full_name != undefined &&
                            option.full_name != null &&
                            (   
                                option.full_name.toUpperCase().toString().includes(upperCaseFilter)
                            )
                        )
                     || 
                    (
                        (
                            option.primary_email != undefined &&
                            option.primary_email != null &&
                            (   
                                option.primary_email.toUpperCase().toString().includes(upperCaseFilter)
                            )
                        )
                    ) 
                    )

                    {
                        return option
                    }
                }
            )
            setFilteredData(filterDataArr)
        } else {
            setFilteredData(dta)
        }
    
}

    const deleteHandleShow = async(datal) => {
        setdltShow(true);
        setdltId(datal);
    }


    const deleteUser = (id) => {
        setLoading(true)
         
        CommonService.httpDeleteService(`user/user/${dltId}`)
        .then(users => {
            setdltShow(false);
            alert.success("User has been deleted successfully");
            getUsers()
            setLoading(false)
            },error =>{
                
                setdltShow(false);
                alert.error(error)
                setLoading(false)
        })

    }

    useEffect(() => {
        let user = {}
        if (localStorage.getItem('user') != null && localStorage.getItem('user') != undefined) {
			user = JSON.parse(localStorage.getItem('user'))
		}
        setUserData(user)
        setLoading(false);
        getUsers();
    }, []);

    const getCourses = async (student) => {
        setLoading(true);
        CommonService.httpGetService(`admin-api/courses/get-student-courses/${student.id}`).then(res => {
            setCourseData(res);
            if(res[0]){
                getClasses(res[0].id)
            }
            
            setShowMoveStudentModal(true)   
            setLoading(false);
        }, error => {
            setLoading(false)
        })

    }

    const getClasses = (courseId) => {
        setSelectedCourseId(courseId)
        CommonService.httpGetService(`admin-api/class/get-classes/${userData.role}/${userData.id}/${courseId}`)
        .then(response => {
            setClassList(response)
        }, error => {
        })
    }

    const handleMoveStudent = () => {
        try {
            setLoading(true);
            if(newClassId){
                CommonService.httpPostService(`admin-api/class_student/migrate-all-class/${selectedStudent.id}/${selectedCourseId}/${newClassId}`, {})
                    .then(response => {
                        alert.success("Student has been moved successfully");
                        setNewClassId(null)
                        setLoading(false);
                        handleCloseMoveModal()
                    }, error => {
                        alert.error(error)
                        setLoading(false);
                    })
            }
        } catch (error) {
            setLoading(false);
        }
    }



    // const certificateHandler = (userId) => {
    //     try {
    //         setLoading(true)
    //         CommonService.httpGetService(`admin-api/class_student/user/download/certificate?user_id=${userId}`)
    //         .then(data => {
    //             setCertificateUrl(data.data.course_certificate_url)
    //             setLoading(false)
    //         })
    //         .catch(err => {

    //         })
    //     } catch(error) {
    //         setLoading(false)
    //     }
    // }

    const fileNameHandler = () => {
        const splitName = username.split(" ")
        const joined = splitName.join("_")
        return joined
    } 
    
    const contractHandler = (userId) => {
        try {
            setLoading(true)
            CommonService.httpGetService(`admin-api/user-contract/user/download/contract?user_id=${userId}`)
            .then(data => {
                setContractUrl(data.data.contract_url)
                setLoading(false)
            })
            .catch(err => {

            })
        } catch(error) {
            setLoading(false)
        }
    }

    const answerSheetHandler = (userId) => {
        try {
            setLoading(true)
            CommonService.httpGetService(`admin-api/course-test/user/download/result?user_id=${userId}`)
            .then(data => {
                setAnswerSheetUrl(data.data.answer_sheet_url)
                setLoading(false)
            })
            .catch(err => {

            })
        } catch(error) {
            setLoading(false)
        }
    }

    const userActivityDownload = () => {
        setShowActLoader(true)
        CommonService.downloadPdf(`admin-api/user-activity/user/download/activity/general/${userId}`).then(fileBlob => {
            let blob = new Blob([fileBlob], {
                type: 'application/pdf'
            });
            FileSaver.saveAs(blob,`${fileNameHandler()}_activity.pdf`);
            setShowActLoader(false)
        }, error => {
            
        })
    }

    const userCertificateDownload = () => {
        setShowCertiLoader(true)
        CommonService.downloadPdf(`admin-api/class_student/user/download/certificate?user_id=${userId}`)
        .then(fileBlob => {
            let blob = new Blob([fileBlob], {
                type: 'application/pdf'
            });
            FileSaver.saveAs(blob, `${fileNameHandler()}_certificate.pdf`);
            setShowCertiLoader(false)
        }, error => {
            console.log(error)
        })
    }

    const downloadContract = (fileURL, fileName) => {
        setShowContractLoader(true)
        CommonService.downloadPdf(fileURL)
            .then(fileBlob => {
                // let fileBlob = res.blob();
                let blob = new Blob([fileBlob], {
                    type: 'application/pdf'
                });
                FileSaver.saveAs(blob, fileName);
                setShowContractLoader(false)
            }, error => {
                setShowContractLoader(false)
            })
    }

    const downloadAnswerSheet = (fileURL, fileName) => {
        setShowTestLoader(true)
        CommonService.downloadPdf(fileURL)
            .then(fileBlob => {
                // let fileBlob = res.blob();
                let blob = new Blob([fileBlob], {
                    type: 'application/pdf'
                });
                FileSaver.saveAs(blob, fileName);
                setShowTestLoader(false)
            }, error => {
                setShowTestLoader(false)
            })
    }

    const downloadAll = async() => {
        await userCertificateDownload()
        await userActivityDownload()
        await downloadAnswerSheet(answerSheetUrl, `${fileNameHandler()}_answer_sheet.pdf`)
        await downloadContract(contractUrl, `${fileNameHandler()}_contract.pdf`)
    }

    return (  
        <div>
           {
            !!content && (
                <div className="not-found">
	              <p className="mb-0"><span className="text-bold mr-3">ALERT:</span>{content} </p>
	            </div>
            )
           } 
        <LoadingOverlay
        active={loading}
        spinner
        text='Loading...'
        className="loaderStyle"
        >
        <Container className="containerBox" fluid>
            <Header></Header>
        
            <Container fluid>
                <div className="heading">
                    <div className="lheading">
                        <span className="headTitle"> Users </span>
                        {/* <span className="searchItem"> 11 entries found </span> */}
                    </div>
                    <div className="rheading">
                        <Link to="/create-user"> <button type="button" className="addNew">+ Add New User</button></Link>
                        {/* <button type="button" className="backBtn" onClick={() => history.goBack()}>Back</button> */}
                    
                    </div>
                </div>
                <div className="subheading">
                    <div className="lsubhead">
                    <input type="text" onChange={e => filterData(e.target.value)} className="tableSearch" placeholder="Search here..." />
                    </div>
                </div>
                {
                            filteredData && filteredData.length > 0 ? (
                <Table responsive>
                    <thead>
                        <tr className="tableHead">
                            {/* <th>#</th> */}
                            {/* <th>Id</th> */}
                            <th>Name</th>
                            <th>Email Address</th>
                            <th>Role</th>
                            <th></th>
                            <th></th>

                        </tr>
                    </thead>
 
                    <tbody>

                    
                             {   filteredData.map((datal, index) => (

                                   datal.status == 'active' ? (
                                    <tr key={index}>
                                    {/* <td>{index + 1}</td> */}
                                    {/* <td>{datal.id}</td> */}
                                    <td>{datal?.full_name}</td>
                                    <td>{datal?.primary_email}</td>
                                    <td>{datal?.role}</td>
                                    {/* {
                                     datal?.roles.map((roletype , index) => (
                                     <td key={index}>{roletype?.name}</td>
                                    ))
                                    } */}
                                    <td>{datal?.role == 'STUDENT' ? 
                                     <button type="button" onClick={() => handleShowMoveModal(datal)} className='addStudent mx-2'>Migrate</button> : null}</td>
                                    <td>
                                    <Link to={`/update-user/${datal.id}`} className=""><i title="Edit Student" className="fas fa-pencil-alt" ></i></Link> 
                                    <i title="Delete Student" className="fas fa-trash-alt"  onClick={() => deleteHandleShow(datal?.id)}></i>
                                    <button className="btn btn-sm" onClick={()=> {
                                        setShowDocs(true)
                                        contractHandler(datal?.id)
                                        answerSheetHandler(datal?.id)
                                        setUsername(datal?.full_name)
                                        setUserId(datal?.id)
                                    }}>
                                    <i title="Student's Documents" className="fas fa-folder" ></i>
                                    </button>
                                    <i title="View Activities" type="button" class="fa fa-eye" aria-hidden="true" onClick={() => window.open(`${location.origin}/user-activity/general/${datal.id}/${datal.full_name}`,'_blank')}></i>
{
    // <button type="button"  className='addStudent mx-2'>View Activity</button>
}                                    </td>

                                    
                                   
                                </tr>
                                   ) : ''
    
                            ))
                         
                        }  
                         {/* , {"id" : id , "text": data} */}



                    </tbody>
                     </Table>
                        ) : (
                        <div className="nofoundRow">
                        <img className="nofoundimg" src={NotFoundIMg} />
                        <span className="nofoundimgCaption">Sorry! Users are not available </span>
                        
                        </div>
                    )
                       }
                       

                                <Modal  show={show}
                                    onHide={handleClose}
                                    backdrop="static"
                                    keyboard={false}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Confirmation</Modal.Title>
                                    </Modal.Header>
                                        <Modal.Body>
                                            Do you want to edit this course?
                                        </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                             No
                                        </Button>
                                        <Button variant="primary">Yes</Button>
                                    </Modal.Footer>
                                </Modal>

                                <Modal  show={dltShow}
                                    onHide={delethandleClose}
                                    backdrop="static"
                                    keyboard={false}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Confirmation</Modal.Title>
                                    </Modal.Header>
                                        <Modal.Body>
                                            Do you want to delete this User?
                                        </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={delethandleClose}>
                                             No
                                        </Button>
                                        <Button variant="primary"  onClick={() => deleteUser(dltId)}>Yes</Button>
                                    </Modal.Footer>
                                </Modal>
                    

                    <Modal show={showMoveStudentModal}
                        onHide={handleCloseMoveModal}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            
                            {
                                courseData && courseData.length > 0 ? 
                                <>
                                <div className="d-flex">
                                Are you sure to move {selectedStudent?.full_name} from current class?
                            </div>
                                <Form  id="CreateEntryForm">
                            <Form.Row>
                                    <Form.Group as={Col} controlId="formGridCode">
                                        <Form.Label>Courses *</Form.Label>
                                        <Form.Control as="select" value={selectedCourseId} custom onChange={e => getClasses(e.target.value,selectedStudent.id)} required>
                                            <option value="">------Select------</option>
                                            {courseData && courseData.map((course, index) => (
                                                <option key={index} value={course.id}>{course.name}</option>
                                            ))}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Please select the class.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridCode">
                                        <Form.Label>Classes *</Form.Label>
                                        <Form.Control as="select" custom onChange={e => setNewClassId(e.target.value)} required>
                                            <option value="">------Select------</option>
                                            {classList && classList.map((classData, index) => (
                                                <option key={index} value={classData.id}>{classData.name}</option>
                                            ))}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Please select the class.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                             
                                </>
                                : `${selectedStudent?.full_name} haven't purchased any courses yet.`
                            }

                        </Modal.Body>
                        {
                                courseData && courseData.length > 0 ?  <Modal.Footer>
                            <Button type="button" variant="secondary" onClick={handleCloseMoveModal}>
                                Cancel
                            </Button>
                            <Button type="button" variant="primary" onClick={()=>handleMoveStudent()}>Confirm</Button>
                        </Modal.Footer> : null
                        }
                    </Modal>
               

            </Container>
        </Container>
        <Modal show={showDocs} onHide={()=>setShowDocs(false)} >
        <Modal.Header closeButton>
          <Modal.Title >{username}'s Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row user-documents">
            <div className="col-sm-6 text-center" onClick={() => {
                userCertificateDownload(userId)
            }}>
            <div className="card mb-3 position-relative pt-3">
            <div className="img-container">
                <img src={CertificateImg}  />
            </div>
            
            {showCertiLoader ?  <div id="wave" className="position-absolute">
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
        </div>
    
    : <i class="fa fa-download position-absolute " aria-hidden="true"></i>}
            <p>Certificate</p>
            </div>
            </div>
         {   
            //  <div className="col-sm-6 text-center">
            // <div className="card mb-3 position-relative pt-3">
            // <div className="img-container">
            //     <img src={TimetableImg}  />
            // </div>
            // <i class="fa fa-download position-absolute " aria-hidden="true"></i>
            // <p>Attendance</p>
            // </div>
            // </div>
        }
        <div className="col-sm-6 text-center" onClick={() => {
            userActivityDownload(userId)
        }}>
        <div className="card mb-3 position-relative pt-3">
        <div className="img-container">
            <img src={ActivitiesImg}  />
        </div>
        {showActLoader ?  <div id="wave" className="position-absolute">
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
        </div>
    
    : <i class="fa fa-download position-absolute " aria-hidden="true"></i>}
        <p>Activities</p>
        </div>
        </div>
            <div className="col-sm-6 text-center" onClick={() => {
                if(answerSheetUrl == null || answerSheetUrl == undefined || answerSheetUrl == "") {
                    setContent("Answer sheet not found for this user")
                    return
                }
                downloadAnswerSheet(answerSheetUrl, `${fileNameHandler()}_answer_sheet.pdf`)
            }}>
            <div className="card mb-3 position-relative pt-3">
            <div className="img-container">
            <img src={TestImg}  />
        </div>
            {showTestLoader ?  <div id="wave" className="position-absolute">
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
        </div>
    
    : <i class="fa fa-download position-absolute " aria-hidden="true"></i>}
            <p>Tests</p>
            </div>
            </div>
            <div className="col-sm-6 text-center" onClick={() => {
                if(contractUrl == null || contractUrl == undefined || contractUrl == "") {
                    setContent("Contract not found for this user")
                    return
                }
                downloadContract(contractUrl, `${fileNameHandler()}_contract.pdf`)
            }}>
           <div className="card mb-3 position-relative pt-3">
           <div className="img-container">
           <img src={ContractImg}  />
       </div>
           {showContractLoader ?  <div id="wave" className="position-absolute">
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
        </div>
    
    : <i class="fa fa-download position-absolute " aria-hidden="true"></i>}
            <p>Contract            </p>
            </div>
            </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={downloadAll}>
          {
            isAllDownload ? 
                "Downloading Docs"
            : " Download All Docs"
          }
           
           
          </Button>
        </Modal.Footer>
      </Modal>
        </LoadingOverlay>


        </div>  
    );
};

export default viewUser;








