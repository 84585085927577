import React, { useState, useContext, useEffect } from "react";
// import "./create-lecture.css";
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container';
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Header from '../../../common/header/header'
// import Pagination from 'react-bootstrap/Pagination'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import {CommonService} from '../../../service/common.service';
import {history} from '../../../_helpers/history'
import LoadingOverlay from 'react-loading-overlay';
import Modal from 'react-bootstrap/Modal'

const createUser = ({match}) => {
    
    const [full_name, setName] = useState('')
    const [role, setRole] = useState('STUDENT')
    const [primary_email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const alert = useAlert();
    const  userId = match.params?.id;

    const [dltShow, setdltShow] = useState(false);
    const delethandleClose = () => setdltShow(false);
    const deleteHandleShow = async () => {
        setdltShow(true);
    }

    // useEffect(() => {
    //     setLoading(true);
    //     if(userId != null) {

    //         setCourseId(userId)
    //         CommonService.httpGetService(`admin-api/course_chapter?id=${userId}`)
    //             .then(data => {
    //                 setChapertDat(data)
    //                 if (data.length > 0) {
    //                     setChapterId(data[0].id)
    //                     setLoading(false);
    //                 }
    //             },error =>{
    //                 alert.error(error)
    //                 setLoading(false);
    //             })
    //     }
    // }, []);

    const handleSubmit = () => {
        setLoading(true);
        const form = document.getElementById("CreateLectureForm");
        // const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
            setLoading(false);
        }
        else {
            handleCreateLectureBtn();

        }
       
    };

    const handleReset = () => {
        setName('')
        setEmail('')
        setPhoneNumber('')
        // setRole(role)
        setValidated(false);
        setdltShow(false);

    };

    function handleCreateLectureBtn () {
        setLoading(true);
        try {
            let obj = {
                "name": full_name,
                "primary_email": primary_email,
                "role" :role,
                "mobile_no" :phoneNumber,
            }
            CommonService.httpPostService(`user/user`, obj)
            .then(res => {
                // let lectId = res.data[0].id;
                // setLectureId(lectId)
                setLoading(false);
                alert.success(`User Created successfully.`);
				},error =>{
                    setLoading(false);
					alert.error(error)
			})
           

        } catch (error) {
            setLoading(false);
            alert.error("Please fill the required details!");
            // alert('Something went wrong with login')
        }
    }



    return (
        <LoadingOverlay
        active={loading}
        spinner
        text='Loading...'
        className="loaderStyle"
        >
        <Container className="containerBox" fluid>
            <Header></Header>           
            <Container fluid>
                <div className="heading">
                    <div className="lheading">
                        <span className="headTitle">Create User </span>
                        {/* <span className="searchItem"> Code : A10922 </span> */}
                    </div>
                    <div className="rheading">
                    <button type="button" className="backBtn" onClick={() => history.goBack()}>Back</button>
                    </div>
                </div>

                <Form className="CreateEntryForm" id="CreateLectureForm" noValidate validated={validated}>
                <Form.Row>
                <Form.Group as={Col} controlId="formGridname">
                            <Form.Label>Name *</Form.Label>
                            <Form.Control type="text" placeholder="" value={full_name} onChange={e => setName(e.target.value)}   pattern="[a-zA-Z0-9\s]+" required>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                Please fill your name.
                        </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridName">
                            <Form.Label>Email Address *</Form.Label>
                            <Form.Control type="text" value={primary_email} onChange={e => setEmail(e.target.value)}  pattern="[^@\s]+@[^@\s]+\.[^@\s]+" placeholder="" required>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                Please fill your email address.
                        </Form.Control.Feedback>
                        </Form.Group>

                    </Form.Row>
                    <Form.Row>
                    <Form.Group as={Col} xs={6} controlId="formGridName">
                            <Form.Label>Phone Number *</Form.Label>
                            <Form.Control type="text" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)}  pattern="[0-9]{10,10}" minLength="10" maxLength="10" placeholder="" required>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                Please fill your phone number.
                        </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} xs={6} controlId="formGridCode">
                            <Form.Label>Role</Form.Label>
                            <Form.Control as="select" custom value={role} onChange={e => setRole(e.target.value)} >
                                <option  value="TEACHER">Teacher</option>
                                <option  value="STUDENT">Student</option>
                                {/* <option  value="ADMIN">ADMIN</option> */}
                                {/* <option  value="VIDEO">VIDEO</option> */}
                            </Form.Control>
                        </Form.Group>

                    </Form.Row>

                    <div className="submitBtnRow">
                        <button type="button" onClick={() => handleSubmit()} className="saveBtn"> Submit </button>
                        <button type="button" onClick={() =>  deleteHandleShow()} className="resetBtn"> Reset </button>
                        {/* <button type="button" className="resetBtn"> <Link to={`/view-lecture-asset/${lectureId}`} className="" disabled={lectureId == null? true: false}>View User</Link> </button> */}

                    </div>
                </Form>
                <Modal show={dltShow}
                        onHide={delethandleClose}
                        backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Do you want to reset this form?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={delethandleClose}>
                                No
                            </Button>
                            <Button variant="primary"  onClick={() => handleReset()}> Yes</Button>
                        </Modal.Footer>
                    </Modal>

            </Container>
        </Container>
        </LoadingOverlay>
    );
};

export default createUser;


