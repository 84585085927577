import React, { useEffect, useState } from 'react';
import socketIOClient from "socket.io-client";
import { CommonService } from '../../../service/common.service';
// import * as kurentoUtils from 'kurento-utils';
var kurentoUtils = require('kurento-utils');

const TeacherKurento = React.memo(function TeacherKurento(props) {
    // let socket = null
    let isPresenter = true;
    var rtcPeer;

    const[showVideo,setShowVideo] = useState(true)
    const[rtcConnection,setRTCConnection] = useState(null)
    const [ socket , setSocket ] = useState(null)
    var localSocket = null
    const[isRejoin,setRejoin] = useState(false)

    useEffect(()=> {
        localSocket = socketIOClient(process.env.stream_url);
        setSocket(localSocket)
        localSocket.on("message", (message) => {
            console.log("Message received: " + message.event);
            switch (message.event) {
                case "ready":
                    if(isPresenter) {
                      sendVideo();
                    }
                    break;
                case "newParticipantArrived":
                    console.log(`${message.username} has joined the session`);
                    break;
                case "receiveVideoAnswer":
                    onReceiveVideoAnswer(message.sdpAnswer);
                    break;
                case "candidate":
                    addIceCandidate(message.candidate);
                    break;
                case "stopMedia":
                    stopVideo()
                    break;
            }
        });
        startVideo()
    },[])

    useEffect(() => {
        debugger
        if(!props.video) {
           var message = {
                event: "stop",
                roomName: props.roomName
          };
          sendMessage(message);
          if(rtcConnection) {
              rtcConnection.dispose()
              stopVideo()
          }
        }else if(props.video && isRejoin){
            // let message = {
            //     event: "presenter",
            //     userName: props.userData.name,
            //     roomName: props.roomName,
            // }
            // sendMessage(message);

            message = {
                event: "start",
                userName: props.userData.name,
                roomName: props.roomName,
            }
            sendMessage(message);
            
        }
    }, [props])

    
    function startVideo() {
        let message = {
                event: "presenter",
                userName: props.userData.name,
                roomName: props.roomName,
            }
            sendMessage(message);
            setShowVideo(true)
            setRejoin(false)
    }


    function stopVideo() {
        setShowVideo(false)
        setRejoin(true)
    }

    const closeEvent = () => {
        // rtcConnection.dispose();
    }




    function sendVideo() {
        setShowVideo(true)
        var constraints = {
            audio: false,
            video: {
                mandatory: {
                    maxWidth: 320,
                    maxFrameRate: 15,
                    minFrameRate: 15,
                },
            },
        };
        var videoBroadcast = document.getElementById("broadcast");

        var options = {
            localVideo: videoBroadcast,
            mediaConstraints: constraints,
            onicecandidate: onIceCandidate
            // dispose : dispose
        };

        
        rtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(options, async  function (
            err
        ) {
            if (err) {
                return console.error(err);
            }
            await this.generateOffer(onOffer);
           
        });
      

        var onOffer = function (err, offer, wp) {
            console.log("sending offer");
            var message = {
                event: "processOffer",
                roomName: props.roomName,
                sdpOffer: offer,
            };
            sendMessage(message);
        };

        function onIceCandidate(candidate, wp) {
            console.log("sending ice candidates");
            var message = {
                event: "candidate",
                roomName: props.roomName,
                candidate: candidate,
            };
            sendMessage(message);
        }
        setRTCConnection(rtcPeer)
    }


    function onReceiveVideoAnswer(sdpAnswer) {
        rtcPeer.processAnswer(sdpAnswer);
    }

    function addIceCandidate(candidate) {
        rtcPeer.addIceCandidate(candidate);
    }

    // utilities
    function sendMessage(message) {
        console.log("sending " + message.event + " message to server",message);
        if(socket){
            socket.emit("message", message);
        }else if(localSocket){
            localSocket.emit("message", message);
        }else{
            console.log('Not able to send message due to socket is undefined ')
        }
    }

    return (
        <>
            {/* {
                showVideo ? <video id="broadcast" style={{ display: "none" }} muted autoPlay playsInline></video> : null
            } */}

<video id="broadcast" style={{ display: "none" }} muted autoPlay playsInline></video>
        </>
    )
});

export default TeacherKurento;