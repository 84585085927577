import loaderImg from '../assets/jitsi_loader.gif'
const Loader = () => {
    return (
        // <div >
        //     <img src={loaderImg}></img>
        // </div>
        <div className='loader'>
        <img className="loader-img" src={loaderImg}></img>
      </div>
    );
};

export default Loader;
